import React from 'react';
import { Flex, Image, Box, Text, useMediaQuery } from '@chakra-ui/core';

import ContentContainer from '../ContentContainer';
import ContentResource from './ContentResource';
import { cloudfrontBaseUrl } from '../../lib/consts';
import { H2 } from '@typography';
import {
  APP_CONTENT_DESKTOP_WIDTH,
  APP_CONTENT_SMALL_DESKTOP_WIDTH,
  APP_CONTENT_MOBILE_WIDTH,
} from '../../styles/sizes';
import { contentResources } from '../../lib/consts';

const ContentResources = () => {
  const [isMobile] = useMediaQuery(`(max-width: ${APP_CONTENT_MOBILE_WIDTH})`);

  const contentResources1 = `${cloudfrontBaseUrl}/homepage-24/home-contentResources1.svg`;

  return (
    <div className="content-resources">
      <ContentContainer
        maxWidth={APP_CONTENT_DESKTOP_WIDTH}
        minWidth={[
          'fit-content',
          APP_CONTENT_MOBILE_WIDTH,
          APP_CONTENT_SMALL_DESKTOP_WIDTH,
        ]}
        width={['100%']}
      >
        <Flex direction={['column', 'column']} paddingX={[12]}>
          <Flex direction={['column', 'column', 'row']}>
            <Box
              display={['flex', 'flex', 'none']}
              paddingTop={[0, 0, '90px']}
              width={['100%']}
              maxWidth={'130px'}
            >
              <Image src={`${cloudfrontBaseUrl}/homepage-24/moneyDateStamp.svg`} />
            </Box>

            <Box pt={[0, 0, '90px']}>
              <Text
                fontSize={['32px', '32px', '48px']}
                fontWeight={'600'}
                textAlign="left"
                lineHeight={'1.2'}
              >
                {isMobile ? (
                  'Resources to help get at those money goals'
                ) : (
                  <>
                    Resources to help get <br />
                    at those money goals
                  </>
                )}
              </Text>
            </Box>
            <Box display={['none', 'none', 'block']}>
              <Image
                mt={[0, 8]}
                ml={[0, -5]}
                width={['100%']}
                maxWidth={'150px'}
                src={`${cloudfrontBaseUrl}/homepage-24/moneyDateStamp.svg`}
              />
            </Box>
          </Flex>

          <Flex mb={[0, '64px']} pt={[18, '48px']} direction="column">
            <Flex direction={['column', 'row']}>
              <ContentResource
                center
                key="cr-1"
                variant="ultraMega"
                imageSource={contentResources1}
                title="Zeta's guide to combining finances"
                description="Everything you need to know about merging your finances as a team."
                ctaUrl="/magazine/combining-finances/"
              />
            </Flex>

            <Flex
              pt={[4, '25px', '50px']}
              direction={['column', 'row']}
              width={'100%'}
              justify={['space-between', 'center', 'center']}
              style={{ gap: '12px' }}
            >
              {contentResources.map(
                ({ title, description, ctaUrl, imageSource, titleBreak }) => (
                  <ContentResource
                    key={`cr-${title}`}
                    title={title}
                    description={description}
                    ctaUrl={ctaUrl}
                    imageSource={imageSource}
                    titleBreak={titleBreak}
                  />
                ),
              )}
            </Flex>
          </Flex>
        </Flex>
      </ContentContainer>
    </div>
  );
};

export default ContentResources;
