import React from 'react';
import { Flex } from '@chakra-ui/core';
import Layout from '../components/layout';
import {
  TopSection,
  ContentResources,
  FamiliesHeartZetaHome,
  AdaptsFamily,
  Unique,
  Metadata,
} from '@home';
import { colors } from '@theme';
import ZetaIsFor from '../components/zeta/ZetaIsFor';

const Index = () => {
  let statusBarColor = colors.transparent;
  const bgColorResult = result => {
    statusBarColor = result;
  };
  return (
    <>
      <Layout
        transparent
        noRootContainer
        showGetStartedMobile={false}
        showGetStartedDesktop={true}
        mode={'home'}
        newNav
        bgColorResult={bgColorResult}
      >
        <Metadata statusBarColor={statusBarColor} />

        <Flex
          className="heading-section"
          width="100%"
          justifyContent="center"
          direction="column"
        >
          <TopSection />
        </Flex>
        <AdaptsFamily />
        <Unique />
        <FamiliesHeartZetaHome />
        <ZetaIsFor />
        <ContentResources />
      </Layout>
    </>
  );
};

export default Index;
