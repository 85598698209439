import React, { useState, useEffect } from 'react';
import { Flex, Image, Box, Text } from '@chakra-ui/core';

import ContentContainer from '../ContentContainer';
import { colors, animations } from '@theme';
import { APP_CONTENT_WIDE_WIDTH } from '../../styles/sizes';
import { H2, H3, H4 } from '@typography';
import { LearnMoreButton } from '@zeta';
import { families } from '../../lib/consts';
import { DesktopOnly, MobileOnly } from '../../layouts/Footer';
import DesktopFrameBg from '../../assets/DesktopFrameBg.png';
import JaMedallion from '../../assets/JaMedallion.png';
import { fontSize, fontWeight, lineHeight, paddingTop, textAlign } from 'styled-system';
import { cloudfrontBaseUrl } from '../../lib/consts';

const AdaptsFamily = () => {
  const [selectedFamily, setSelectedFamily] = useState(families[0]);
  const FamilySection1 = `${cloudfrontBaseUrl}/homepage-24/familySection1.jpg`;
  const FamilySection2 = `${cloudfrontBaseUrl}/homepage-24/familySection2.jpg`;
  const FamilySection3 = `${cloudfrontBaseUrl}/homepage-24/familySection3.jpg`;

  useEffect(() => {
    const interval = setInterval(() => {
      if (selectedFamily.index !== 4)
        setSelectedFamily(families[selectedFamily.index + 1]);
      else setSelectedFamily(families[0]);
    }, 5000);

    return () => clearInterval(interval);
  }, [selectedFamily.index]);

  return (
    <div className="joint-accounts" style={{ backgroundColor: 'white' }}>
      <ContentContainer maxWidth={APP_CONTENT_WIDE_WIDTH} minWidth={'auto'}>
        <Flex
          height={'auto'}
          pt={['48px', '48px', '48px', '93px']}
          justify={'center'}
          direction={'column'}
          paddingX={[12, 12, 12, 0]}
        >
          <Flex justifyContent="center">
            <Text
              textAlign={'center'}
              fontSize={['32px', '32px', '32px', '48px']}
              lineHeight={'1.2'}
              fontWeight="600"
              letterSpacing={'-0.78px'}
              color={colors.darkGray}
            >
              Zeta adapts to the shape and size of your family
            </Text>
          </Flex>

          <Flex justifyContent="center" pt="12px">
            <Text
              fontSize={['16px', '16px', '16px', '24px']}
              fontWeight={'400'}
              lineHeight={'1.7'}
              textAlign="center"
              paddingX={[6, 12, 16, '10%']}
              color={colors.grayGray900}
            >
              No two families are exactly alike. We have flexible accounts that tailor to
              your unique needs - whether you are married, single, co-parenting,
              contributing to multiple households, or anything in between.
            </Text>
          </Flex>

          <Flex
            direction={['column', 'column', 'column', 'row']}
            pt={['56px', '56px', '56px', '77px']}
            justifyContent="space-between"
            alignItems={['center', 'center', 'center', 'end']}
          >
            <Flex
              justifyContent="flex-end"
              direction="column"
              px="12px"
              pb={['64px', '64px', '64px', 0]}
              maxWidth={'400px'}
            >
              <Flex justifyContent="center">
                <Image width={'100%'} maxWidth={'300px'} src={FamilySection1} />
              </Flex>
              <Text
                color={colors.darkGray}
                fontSize={['24px', '24px', '24px', '32px']}
                fontWeight={'600'}
                style={{
                  textAlign: 'center',
                  paddingTop: '24px',
                }}
              >
                Invite
              </Text>
              <Flex justifyContent="center" px="24px">
                <Text
                  color={colors.grayGray900}
                  fontSize={['16px', '16px', '16px', '18px']}
                  fontWeight={'400'}
                  lineHeight={['1.5', '1.5', '1.5', '1.7']}
                  paddingTop={['8px', '8px', '8px', '16px']}
                  paddingX={[2, 2, 2, 0]}
                  textAlign={'center'}
                >
                  Invite up to 5 members to your account - they can be co-owners, adult
                  companions or teens (13+).
                </Text>
              </Flex>
            </Flex>
            <Flex
              justifyContent="flex-end"
              direction="column"
              px="12px"
              pb={['64px', '64px', '64px', 0]}
              maxWidth={'400px'}
            >
              <Flex justifyContent="center">
                <Image width={'100%'} maxWidth={'300px'} src={FamilySection2} />
              </Flex>
              <Text
                color={colors.darkGray}
                fontSize={['24px', '24px', '24px', '32px']}
                fontWeight={'600'}
                style={{
                  textAlign: 'center',
                  paddingTop: '24px',
                }}
              >
                Control
              </Text>
              <Flex justifyContent="center" px="24px">
                <Text
                  color={colors.grayGray900}
                  fontSize={['16px', '16px', '16px', '18px']}
                  fontWeight={'400'}
                  lineHeight={['1.5', '1.5', '1.5', '1.7']}
                  paddingTop={['8px', '8px', '8px', '16px']}
                  paddingX={[2, 2, 2, 0]}
                  textAlign={'center'}
                >
                  Set custom permissions for each member so you control exactly who has
                  access to your accounts.
                </Text>
              </Flex>
            </Flex>
            <Flex
              justifyContent="flex-end"
              direction="column"
              pb={['64px', '64px', '64px', 0]}
              paddingX="12px"
              maxWidth={'400px'}
            >
              <Flex justifyContent="center">
                <Image width={'100%'} maxWidth={'300px'} src={FamilySection3} />
              </Flex>
              <Text
                color={colors.darkGray}
                fontSize={['24px', '24px', '24px', '32px']}
                fontWeight={'600'}
                style={{
                  textAlign: 'center',
                  paddingTop: '24px',
                }}
              >
                Collaborate
              </Text>
              <Flex justifyContent="center" px="24px">
                <Text
                  color={colors.grayGray900}
                  fontSize={['16px', '16px', '16px', '18px']}
                  fontWeight={'400'}
                  lineHeight={['1.5', '1.5', '1.5', '1.7']}
                  paddingTop={['8px', '8px', '8px', '16px']}
                  paddingX={[2, 2, 2, 0]}
                  textAlign={'center'}
                >
                  Track your shared bills, save for goals together while staying in sync
                  with in-app messaging.
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        {/* Desktop version */}
      </ContentContainer>
    </div>
  );
};

export default AdaptsFamily;
