import React from 'react';
import { Flex, Box, Image, Text } from '@chakra-ui/core';
import Lottie from 'react-lottie';

import { colors } from '@theme';
import { APP_CONTENT_DESKTOP_WIDTH, APP_CONTENT_WIDE_WIDTH } from '../../styles/sizes';
import ContentContainer from '../ContentContainer';
import { H2, H3 } from '@typography';
import animation from '../../lotties/circle-animation.json';
import { cloudfrontBaseUrl } from '../../lib/consts';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animation,
};

const FamiliesHeartZetaHome = () => {
  const familiesHeartZeta1 = `${cloudfrontBaseUrl}/homepage-24/home-familiesHeartZeta1.svg`;
  const familiesHeartZeta2 = `${cloudfrontBaseUrl}/homepage-24/home-familiesHeartZeta2.svg`;
  const familiesHeartZeta3 = `${cloudfrontBaseUrl}/homepage-24/home-familiesHeartZeta3.svg`;
  const familiesHeartZeta4 = `${cloudfrontBaseUrl}/homepage-24/home-familiesHeartZeta4.svg`;
  const familiesHeartZeta5 = `${cloudfrontBaseUrl}/homepage-24/home-familiesHeartZeta5.svg`;

  return (
    <Box
      backgroundSize={'cover'}
      backgroundPosition={'center'}
      backgroundRepeat={'no-repeat'}
      background={[
        `radial-gradient(90% 90% at top left, #98D3B699 0%, transparent), radial-gradient(90% 90% at top right, #F2D02233 0%, transparent), radial-gradient(90% 90% at right, #FAE6E633 0%, transparent), radial-gradient(90% 90% at bottom left, #6EDAF233 0%, transparent)`,
        `radial-gradient(90% 90% at top left, #98D3B633 0%, transparent), radial-gradient(90% 90% at top right, #F2D02233 0%, transparent), radial-gradient(90% 90% at right, #FAE6E633 0%, transparent), radial-gradient(90% 90% at bottom left, #6EDAF233 0%, transparent)`,
        `radial-gradient(90% 90% at top left, #98D3B611 0%, transparent), radial-gradient(90% 90% at top right, #6EDAF222 0%, transparent), radial-gradient(90% 90% at bottom right, #FAE4E455 0%, transparent), radial-gradient(90% 90% at bottom left, #F2D02233 0%, transparent)`,
        ` radial-gradient(
            65% 90% at 100% 90%,
            #fae4e411 25%,
            #fae4e455 70%,
            transparent
          ),
          radial-gradient(90% 90% at top left, #98d3b611 0%, transparent),
          radial-gradient(60% 50% at top right, #6edaf233 0%, transparent),
          radial-gradient(75% 90% at bottom right, #fae4e455 10%, transparent),
          radial-gradient(90% 90% at bottom left, #f2d02233 0%, transparent);`,
      ]}
    >
      <ContentContainer maxWidth={APP_CONTENT_WIDE_WIDTH} minWidth="auto" width={'100%'}>
        <Flex
          justify="space-between"
          align="center"
          direction="row"
          mt={4}
          pt={['56px', '56px', '56px', '88px']}
          paddingBottom={['48px', '64px', '96px', '128px']}
        >
          <Flex direction="column" align={'center'}>
            <Flex align="center" justify="center" flexDirection="column" width="100%">
              <Text
                textAlign="center"
                fontSize={['40px', '40px', '40px', '48px']}
                fontWeight={'600'}
                color={colors.black}
              >
                Families 🖤 Zeta
              </Text>
              <Text
                width="100%"
                fontSize={['16px', '16px', '16px', '24px']}
                fontWeight={'400'}
                textAlign="center"
                lineHeight={['1.5', '1.5', '1.5', '1.3']}
                maxWidth="70%"
                paddingTop={['8px', '8px', '8px', '23px']}
                color={colors.grayGray900}
              >
                Zeta's aim is to help you win, no matter what stage you are at in your
                relationship or life. Here's what our members have to say.
              </Text>
            </Flex>

            <Flex
              flexDirection={['column', 'row', 'row', 'row']}
              paddingTop={['32px', '64px', '96px', '96px']}
              paddingX={['2rem', '4rem', '5rem', '1rem']}
              justifyContent={'center'}
              style={{ columnGap: '1rem', rowGap: '3rem' }}
              width="100%"
            >
              <Flex flexDirection="column" flex={1} justifyContent={'space-between'}>
                <Image
                  src={familiesHeartZeta1}
                  width="100%"
                  paddingBottom={['3rem', 0, 0, 0]}
                />
                <Image src={familiesHeartZeta2} width="100%" />
              </Flex>
              <Flex flexDirection="column" flex={1} justifyContent={'space-between'}>
                <Image src={familiesHeartZeta3} width="100%" />
              </Flex>
              <Flex flexDirection="column" flex={1} justifyContent={'space-between'}>
                <Image
                  src={familiesHeartZeta4}
                  width="100%"
                  paddingBottom={['3rem', 0, 0, 0]}
                />
                <Image src={familiesHeartZeta5} width="100%" />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </ContentContainer>
    </Box>
  );
};

export default FamiliesHeartZetaHome;
